import * as React from "react";

const Hero = ({data}) => {
    return (
        <div>
            <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-custom_widget section--hero--mobile" data-widget-type="custom_widget" data-x="0" data-w="12">
                        <div id="hs_cos_wrapper_module_15771254628871296" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" data-hs-cos-general-type="widget" data-hs-cos-type="module">
                            <img src="https://www.4kscore.com/hubfs/science-1.jpg" style={{ width: "100%", display: "block", margin: "0px auto" }} />
                        </div>

                    </div>
                </div>
            </div>

            <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-cell section--hero" style={{ backgroundImage: "url('"+data.image.publicUrl+"')" }} data-widget-type="cell" data-x="0" data-w="12">

                        <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-custom_widget hero--title" data-widget-type="custom_widget" data-x="0" data-w="12">
                                    <div id="hs_cos_wrapper_module_15558271722392923" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_15558271722392923_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h1 style={{ textAlign: "right" }} dangerouslySetInnerHTML={{__html: data.text}}></h1></span></div>

                                </div>
                            </div>
                        </div>

                        <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-cell container" data-widget-type="cell" data-x="0" data-w="12">

                                    <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-cell hero--content" data-widget-type="cell" data-x="0" data-w="12">

                                                <div className="row-fluid-wrapper row-depth-1 row-number-6 ">
                                                    <div className="row-fluid ">
                                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                            <div id="hs_cos_wrapper_module_15558267449902677" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_15558267449902677_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><table className="heroinsurancetitle" style={{ borderCollapse: "collapse", tableLayout: "fixed", marginLeft: "auto", marginRight: "auto" }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ padding: "4px" }} className="heroinsurancetitle" dangerouslySetInnerHTML={{__html:data.tagline}}>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table></span></div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero