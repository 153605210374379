import * as React from "react";

const BlockThree = ({data}) => {
    return (
        <div>
            <div className="row-fluid-wrapper row-depth-1 row-number-16 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-cell equal-heights full-width sp_box_shadow" data-widget-type="cell" data-x="0" data-w="12">

                        <div className="row-fluid-wrapper row-depth-1 row-number-17 ">
                            <div className="row-fluid ">
                                <div className="span6 widget-span widget-type-cell sp_vertical_middle" data-widget-type="cell" data-x="0" data-w="6">

                                    <div className="row-fluid-wrapper row-depth-1 row-number-18 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                <div id="hs_cos_wrapper_module_1576860184574635" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-linked_image" data-hs-cos-general-type="widget" data-hs-cos-type="module">











                                                    <span id="hs_cos_wrapper_module_1576860184574635_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_linked_image" data-hs-cos-general-type="widget" data-hs-cos-type="linked_image"><img src={data.image.publicUrl} className="hs-image-widget " style={{ maxWidth: "100%", height: "auto" }} alt="map1-1" title="map1-1" /></span></div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="span6 widget-span widget-type-cell " data-widget-type="cell" data-x="6" data-w="6">

                                    <div className="row-fluid-wrapper row-depth-1 row-number-19 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                <div id="hs_cos_wrapper_module_15558996062283912" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_15558996062283912_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
                                                    <div dangerouslySetInnerHTML={{__html: data.content}}></div>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row-fluid-wrapper row-depth-1 row-number-20 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{ height: "80px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                        <div id="hs_cos_wrapper_module_15558996062283913" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockThree;