import * as React from "react";

const BlockSix = ({data}) => {
    return (
        <div className="row-fluid-wrapper row-depth-1 row-number-30 ">
            <div className="row-fluid ">
                <div className="span12 widget-span widget-type-cell full-width" data-widget-type="cell" data-x="0" data-w="12">

                    <div className="row-fluid-wrapper row-depth-1 row-number-31 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-cell sp_col_text" data-widget-type="cell" data-x="0" data-w="12">

                                <div className="row-fluid-wrapper row-depth-1 row-number-32 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_1555901026858684" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1555901026858684_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
                                                <div dangerouslySetInnerHTML={{__html: data.block2}}></div>
                                                </span></div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row-fluid-wrapper row-depth-1 row-number-33 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{ height: "40px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                <div id="hs_cos_wrapper_module_1556204282446223" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                            </div>
                        </div>
                    </div>

                    <div className="row-fluid-wrapper row-depth-1 row-number-34 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-custom_widget hidden-desktop" style={{ height: "20px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                <div id="hs_cos_wrapper_module_1556204317423252" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BlockSix;