import * as React from "react";

const BlockFour = ({data}) => {
    return (
        <div>
            <div className="row-fluid-wrapper row-depth-1 row-number-21 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-cell equal-heights full-width sp_box_shadow" data-widget-type="cell" data-x="0" data-w="12">

                        <div className="row-fluid-wrapper row-depth-1 row-number-22 ">
                            <div className="row-fluid ">
                                <div className="span6 widget-span widget-type-cell sp_vertical_middle" data-widget-type="cell" data-x="0" data-w="6">

                                    <div className="row-fluid-wrapper row-depth-1 row-number-23 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                <div id="hs_cos_wrapper_module_1576860196964642" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-linked_image" data-hs-cos-general-type="widget" data-hs-cos-type="module">











                                                    <span id="hs_cos_wrapper_module_1576860196964642_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_linked_image" data-hs-cos-general-type="widget" data-hs-cos-type="linked_image"><img src={data.image.publicUrl} className="hs-image-widget " style={{ maxWidth: "100%", height: "auto" }} alt="map2-1" title="map2-1" /></span></div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="span6 widget-span widget-type-cell " data-widget-type="cell" data-x="6" data-w="6">

                                    <div className="row-fluid-wrapper row-depth-1 row-number-24 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                <div id="hs_cos_wrapper_module_1555900594414581" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1555900594414581_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
                                                    <div dangerouslySetInnerHTML={{__html: data.content}}></div>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row-fluid-wrapper row-depth-1 row-number-25 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{ height: "40px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                        <div id="hs_cos_wrapper_module_1555900600246595" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockFour;