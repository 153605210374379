import { useStaticQuery, graphql } from "gatsby"

export const useScientificQuery = () => {


    const data = useStaticQuery(graphql`
    query ScientificQuery {
        wpPage(title: {eq: "SCIENCE BEHIND THE SCORE"}) {
          ACF_ScientificPage {
            scientificHero {
                image {
                    publicUrl
                }
                text
                tagline
            }

            scientficContent {
                block1
                headline2

                map1 {
                    image{
                        publicUrl
                    }
                    content
                }

                map2 {
                    image{
                        publicUrl
                    }
                    content
                }

                headline3
                block2
                block3

                whentouseimage {
                    publicUrl
                }

                references

            }

            scientificMetaData{
                metaTitle
                metaDescription
            }

            
        }
        }
    }     
    `)
    return data.wpPage.ACF_ScientificPage;
}