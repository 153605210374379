import * as React from "react";
import Layout from "../components/global/Layout";
import Hero from "../components/science-behind-the-score/Hero";
import BlockOne from "../components/science-behind-the-score/BlockOne";
import BlockTwo from "../components/science-behind-the-score/BlockTwo";
import BlockThree from "../components/science-behind-the-score/BlockThree";
import BlockFour from "../components/science-behind-the-score/BlockFour";
import BlockFive from "../components/science-behind-the-score/BlockFive";
import BlockSix from "../components/science-behind-the-score/BlockSix";
import BlockSeven from "../components/science-behind-the-score/BlockSeven";
import BlockEight from "../components/science-behind-the-score/BlockEight";
import BlockNine from "../components/science-behind-the-score/BlockNine";
import { useScientificQuery } from "../hooks/useScientificQuery";
import Helmet from "react-helmet";

const ScienceBehindTheScorePage = () => {
    const data = useScientificQuery();
    return (
      <Layout>
        <Hero data={data.scientificHero} />
        <BlockOne data={data.scientficContent} />
        <BlockTwo data={data.scientficContent} />
        <BlockThree data={data.scientficContent.map1} />
        <BlockFour data={data.scientficContent.map2} />
        <BlockFive data={data.scientficContent} />
        <BlockSix data={data.scientficContent} />
        <BlockSeven data={data.scientficContent} />
        <BlockEight data={data.scientficContent} />
        <BlockNine data={data.scientficContent}/>
        <Helmet>
          <title>{data.scientificMetaData.metaTitle}</title>
          <meta name="description" content={data.scientificMetaData.metaDescription}  />
        </Helmet>
      </Layout>
    )
  };
  

  export default ScienceBehindTheScorePage;